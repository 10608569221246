/** @jsx jsx */
import { FC, useEffect } from 'react'
import { navigate } from 'gatsby-link'
import urls from '~/common/urls'
import { useCustomerContext } from '~/context/customer-context'

type Props = {}

const Logout: FC<Props> = () => {
  const { logout } = useCustomerContext()

  useEffect(() => {
    const logoutUser = async () => {
      await logout(() => {
        navigate(urls.pages.base)
      })
    }

    logoutUser()
  }, [logout])

  return null
}

export default Logout
